import classes from './Quote.module.scss';
import Card from "./Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useDispatch, useSelector} from "react-redux";
import {quotesActions} from "../store/quotes";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
const Quote = ({quote, setToggledTrue}) => {
    const { trackEvent } = useMatomo();
    const userId = useSelector(state => state.auth.userId);
    const dispatch = useDispatch();
    const toggleFaVHandler = e => {
        e.preventDefault();
        setToggledTrue(true);
        dispatch(quotesActions.toggleFavouriteQuote({
            quoteId: quote.$id,
            userId
        }));
        trackEvent({
            category: 'Favourites',
            action: 'Toggled Quote Favourite',
            name: quote.text
        });
    }
    return (
        <Card>
            <h2 className={classes['title']}>
                {quote.text ? quote.text : 'Loading...'}
            </h2>
            <FontAwesomeIcon
                icon={quote.favourite ? solid('star') : regular('star')}
                className={classes['fav']}
                onClick={toggleFaVHandler}
            />
        </Card>
    );
};

export default Quote;