import {Account, Client, Databases, Locale, Teams} from "appwrite";

const client = new Client();

export const account = new Account(client);
export const databases = new Databases(client, 'default');
export const locale = new Locale(client);
export const teams = new Teams(client);

client.setEndpoint('https://api.sweetquotes.space/v1') // Your API Endpoint
    .setProject('62b1cc9bcb152e92a4f0') // Your project ID
;

export const quotesCollection = '62b1cca98193158a4968';
export const userDataCollection = '62b6a4a9e8a571fce2e7';
export const curatorTeamId = '62b5685522c37d600dd1';
export default client;