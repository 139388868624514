import {useRef, useState, useEffect} from "react";
import classes from './ForgotPassword.module.scss';
import {account} from "../api";
// import {useDispatch} from "react-redux";
// import {useHistory} from "react-router-dom";
import Form from "../components/Form";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
const ForgotPassword = () => {
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView({
            documentTitle: 'Forgot Password'
        });
    }, [trackPageView]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const emailRef = useRef();
    // const dispatch = useDispatch();
    // const history = useHistory();
    const onFormSubmitHandler = async e => {
        setError('');
        setSuccess('');
        e.preventDefault();
        let promise = account.createRecovery(emailRef.current.value, `${window.location.protocol}//${window.location.host}/reset-password`);
        // const local = await locale.get()
        promise.then(function (response) {
            setSuccess('Success: A reset link has been sent');
            // history.push('/')
            trackEvent({
                category: 'Auth',
                action: 'Forgot password',
                name: emailRef.current.value
            });
            emailRef.current.value = '';
        }, function (error) {
            setError(error.toString().substring(error.toString().indexOf(':') + 1))
        });
    }
    useEffect(() => {
        console.log(window.location)
    }, [])
    return (
        <Form formHandler={onFormSubmitHandler}>
            <div className={`${classes['u-margin-bottom-medium']}`}>
                <h2 className={classes["heading-primary"]}>
                    Forgot your password?
                </h2>
                <p className={classes['text']}>Please enter the email address you use to sign in below</p>
            </div>
            <div className={classes["form__group"]}>
                <input type="email" id="email" className={classes["form__input"]} placeholder="Email address" required ref={emailRef}/>
                <label htmlFor="email" className={classes["form__label"]}>Email address</label>
                {error && <p htmlFor="password" className={classes["form__error"]}>{error}</p>}
                {success && <p htmlFor="password" className={classes["form__success"]}>{success}</p>}
            </div>
            <div className={classes["form__group"]}>
                <button className={`${classes['button']}`} >Request password reset &rarr;</button>
            </div>
        </Form>
    )
};

export default ForgotPassword;