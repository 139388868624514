import Layout from "./components/Layout";
import {Redirect, Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AddQuote from "./pages/AddQuote";
import {useCallback, useEffect, useMemo, useState} from "react";
import {locale, account, databases, quotesCollection, userDataCollection, curatorTeamId} from "./api";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "./store/auth";
import {quotesActions} from './store/quotes';
import AllQuotes from "./pages/AllQuotes";
import FavouriteQuotes from "./pages/FavouriteQuotes";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import SignUp from "./pages/SignUp";

function App() {
    const [initialLoad, setInitialLoad] = useState(true);
    const [toggledTrue, setToggledTrue] = useState(false);
    const quotes = useSelector(state => state.quotes);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const userId = useSelector(state => state.auth.userId);
    const dispatch = useDispatch();
    const setAccount = useMemo(() => async () => {
        const local = await locale.get();
        try {
            const accountDetails = await account.get();
            dispatch(authActions.cookieLogin({...accountDetails, ...local}));
        } catch (e) {
            dispatch(authActions.logout(null))
        }
    }, [dispatch]);
    const setQuotes = useMemo(() => () => {
        let promise = databases.listDocuments(quotesCollection, undefined,100);

        promise.then(function (response) {
            dispatch(quotesActions.setQuotes(response))
            // console.log(response); // Success
        }, function (error) {
            console.log(error); // Failure
        });
    }, [dispatch]);

    const setFavorites = useCallback(async (justLoggedIn) => {
        const cookieFallback = localStorage.getItem('cookieFallback');
        const loggedIn = localStorage.getItem('loggedIn');
        const updateFavourites = (userFav) => {
            const favourites = quotes.quotes.filter(quote => userFav.includes(quote.$id));
            dispatch(quotesActions.setFavourites(favourites));
        }
        if (isLoggedIn) {
            let promise;
            if (localStorage.getItem('userData')) {
                if (initialLoad || justLoggedIn) {
                    setInitialLoad(false);
                    promise = databases.getDocument(userDataCollection, userId);
                } else {
                    if (toggledTrue) {
                        promise = databases.updateDocument(userDataCollection, userId, {
                            ...JSON.parse(localStorage.getItem('userData'))
                        });
                    } else {
                        promise = databases.getDocument(userDataCollection, userId);
                    }
                }
            } else {
                promise = databases.getDocument(userDataCollection, userId);
            }
            promise.then(function (response) {
                let userData = response;
                localStorage.setItem('userData', JSON.stringify({
                    favourites: userData.favourites,
                    history: userData.history
                }))
                userData = JSON.parse(localStorage.getItem('userData'));
                updateFavourites(userData.favourites);
                return;
            }, function (error) {
                const userData = JSON.parse(localStorage.getItem('userData'));
                updateFavourites(userData.favourites);
                return;
            });
            return;
        } else if ((!cookieFallback && loggedIn!=='1') || (cookieFallback === '[]' && loggedIn!=='1')) {
            const userData = JSON.parse(localStorage.getItem('userData'));
            if (userData) {
                updateFavourites(userData.favourites);
            }
        }
    }, [dispatch, initialLoad, isLoggedIn, quotes.quotes, toggledTrue, userId]);
    useEffect(() => {
        setQuotes();
        const cookieFallback = localStorage.getItem('cookieFallback');
        const loggedIn = localStorage.getItem('loggedIn');
        if ((!cookieFallback && loggedIn!=='1') || (cookieFallback === '[]' && loggedIn!=='1')) {
            return
        }
        setAccount();
        // console.log('getting account into')
    }, [setAccount, setQuotes]);
    useEffect(() => {
        setFavorites();
    }, [setFavorites]);
    const getMemberships = useCallback(async (userID) => {
            try {
                const url = "https://extra.api.sweetquotes.space"
                // const url = "http://localhost:3032"
                const jwtObject = await account.createJWT();
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userId: userID,
                        jwt: jwtObject.jwt
                    }),
                })
                const data = await response.json()
                if(data.roles.includes('curator')){
                    dispatch(authActions.setCuratorStatus(true))
                } else {
                    dispatch(authActions.setCuratorStatus(false))
                }
            } catch (e) {
                console.log(e)
                dispatch(authActions.setCuratorStatus(false));
                // console.clear();
            }
            return
    }, [dispatch]);
    useEffect(() => {
        if(isLoggedIn){
            getMemberships(userId);
        }
    }, [isLoggedIn, dispatch, userId, getMemberships]);
    return (
        <Layout>
            <Switch>
                <Route path='/' exact>
                    <Home setToggledTrue={setToggledTrue}/>
                </Route>
                <Route path='/all-quotes' exact>
                    <AllQuotes setToggledTrue={setToggledTrue}/>
                </Route>
                <Route path='/favourite-quotes' exact>
                    <FavouriteQuotes setFav={setFavorites} setToggledTrue={setToggledTrue}/>
                </Route>
                <Route path='/login'>
                    {isLoggedIn ? <Redirect to='/'/> : <Login setToggledTrue={setToggledTrue} setFav={setFavorites}/>}
                </Route>
                <Route path='/forgot-password'>
                    {isLoggedIn ? <Redirect to='/'/> : <ForgotPassword/>}
                </Route>
                <Route path='/reset-password'>
                    {isLoggedIn ? <Redirect to='/'/> :  <ResetPassword/>}
                </Route>
                <Route path='/sign-up'>
                    {isLoggedIn ? <Redirect to='/'/> : <SignUp/>}
                </Route>
                <Route path='/add-quote'>
                    {isLoggedIn ? <AddQuote/> : <Login/>}
                </Route>
            </Switch>
        </Layout>
    );
}

export default App;
