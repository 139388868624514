import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from "react-redux";
import store from "./store";
import './sassStyles/index.scss';
import {BrowserRouter} from "react-router-dom";
import {createInstance, MatomoProvider} from "@jonkoops/matomo-tracker-react";

const instance = createInstance({
    urlBase: 'https://analytics.zedpro.me',
    siteId: 33,
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <MatomoProvider value={instance}>
            <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
        </MatomoProvider>
);