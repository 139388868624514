import {configureStore} from "@reduxjs/toolkit";
import authSlice from './auth'
import quotesSlice from './quotes'
const store = configureStore({
    reducer: {
        auth: authSlice,
        quotes: quotesSlice
    }
});

export default store;