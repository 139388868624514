import {useRef, useState, useEffect, useCallback} from "react";
import classes from './ResetPassword.module.scss';
import {account} from "../api";
import {useHistory, useLocation} from "react-router-dom";
import Form from "../components/Form";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
const ResetPassword = () => {
    const { search } = useLocation();
    const history = useHistory();
    const [counter, setCounter] = useState(null);
    const countdown = useCallback((callback, seconds) => {
        if(counter===null){
            setCounter(seconds);
        } else if(counter===0){
            callback();
        }
    }, [counter]);
    const searchParams = new URLSearchParams(search);
    const userId = searchParams.get('userId');
    const secret = searchParams.get('secret');
    const expire = searchParams.get('expire');
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView({
            documentTitle: 'Reset Password'
        });
    }, [trackPageView]);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [passwordHasChanged, setPasswordHasChanged] = useState(false);
    const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(false);
    const [confirmPasswordHasChanged, setConfirmPasswordHasChanged] = useState(false);
    const [error, setError] = useState('');
    const [passwordNotSame, setPasswordNotSame] = useState('');
    const [success, setSuccess] = useState('');
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    useEffect(() => {
        const hasExpired = Math.floor(Date.now() / 1000) > expire
        if(hasExpired){
            countdown(() => {
                history.push('/forgot-password')
            }, 5)
            setError(`This link has expired please request another. This page will redirect in ${counter} seconds`)
        }
        const interval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter => counter - 1);
            }
            if (counter === 0) {
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [counter, expire, countdown, history]);
    useEffect(() => {
        if(success){
            countdown(() => {
                history.push('/login')
            }, 5)
            setSuccess(`Success: Password has changed. This page will redirect in ${counter} seconds`);
        }
    }, [counter, success, countdown, history]);
    const onFormSubmitHandler = async e => {
        e.preventDefault();
        let promise = account.updateRecovery(userId, secret, passwordRef.current.value, confirmPasswordRef.current.value);
        // const local = await locale.get()
        promise.then(function (response) {
            setSuccess(`Success: Password has changed. This page will redirect in ${counter} seconds`);
            // history.push('/')
            trackEvent({
                category: 'Auth',
                action: 'Reset Password',
                name: userId
            });
        }, function (error) {
            setError(error.toString().substring(error.toString().indexOf(':') + 1))
        });
    }
    const onPasswordChangeHandler = e => {
        setPasswordHasChanged(true);
        if(passwordRef.current.value.length>7){
            setPasswordIsValid(true);
        } else {
            setPasswordIsValid(false);
        }
    }
    const onConfirmPasswordChangeHandler = e => {
        setConfirmPasswordHasChanged(true);
        if(confirmPasswordRef.current.value.length>7){
            setConfirmPasswordIsValid(true);
        } else {
            setConfirmPasswordIsValid(false);
        }
        if(passwordRef.current.value === confirmPasswordRef.current.value){
            setPasswordNotSame('');
        }
    }
    const onConfirmPasswordBlurHandler = e => {
        if(passwordRef.current.value !== confirmPasswordRef.current.value){
            setPasswordNotSame('The password entered does not match the one above');
        } else {
            setPasswordNotSame('');
        }
    }

    return (
        <Form formHandler={onFormSubmitHandler}>
            <div className={`${classes['u-margin-bottom-medium']}`}>
                <h2 className={classes["heading-primary"]}>
                   Reset Password
                </h2>
            </div>
            <div className={classes["form__group"]}>
                <input type="password" id="password" minLength={8} className={classes["form__input"]} placeholder="Password" required ref={passwordRef} onChange={onPasswordChangeHandler}/>
                <label htmlFor="password" className={classes["form__label"]}>Password</label>
            </div>
            <div className={classes["form__group"]}>
                <input type="password" id="passwordAgain" minLength={8} className={classes["form__input"]} placeholder="Confirm Password" required ref={confirmPasswordRef} onChange={onConfirmPasswordChangeHandler} onBlur={onConfirmPasswordBlurHandler}/>
                <label htmlFor="passwordAgain" className={classes["form__label"]}>Confirm Password</label>
                {error && <p htmlFor="password" className={classes["form__error"]}>{error}</p>}
                {success && <p htmlFor="password" className={classes["form__success"]}>{success}</p>}
                {passwordNotSame && <p htmlFor="passwordAgain" className={classes["form__error"]}>{passwordNotSame}</p>}
            </div>
            <div className={classes["form__group"]}>
                <button className={`${classes['button']}`} disabled={(passwordHasChanged && confirmPasswordHasChanged && !passwordIsValid && !confirmPasswordIsValid) || passwordNotSame}>Reset Password &rarr;</button>
            </div>
        </Form>
    )
};

export default ResetPassword;