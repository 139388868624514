import {createSlice} from "@reduxjs/toolkit";

const quotesSlice = createSlice({
    name: 'quotes',
    initialState: {
        total: null,
        quotes: [],
        userData: {
            favourites: [],
            history: []
        }
    },
    reducers: {
        setQuotes(state, action){
            state.total = action.payload.total;
            state.quotes = action.payload.documents;
        },
        addQuote(state, action){
            state.total = state.total+1;
            state.quotes.push(action.payload);
        },
        toggleFavouriteQuote(state, action){
            const status = state.quotes.filter(quote => quote.$id === action.payload.quoteId)[0].favourite;
            state.quotes.filter(quote => quote.$id === action.payload.quoteId)[0].favourite = !status;
            if(!state.userData.favourites.includes(action.payload.quoteId)){
                state.userData.favourites.push(action.payload.quoteId);
            } else {
                state.userData.favourites = state.userData.favourites.filter(fav => fav !== action.payload.quoteId);
            }
            if(localStorage.getItem('userData')===null){
                localStorage.setItem('userData', JSON.stringify({
                    ...state.userData,
                    favourites: [
                        action.payload.quoteId
                    ]
                }
                ));
            } else {
                const currentUserData = JSON.parse(localStorage.getItem('userData'));
                let newUserData;
                if(currentUserData.favourites.includes(action.payload.quoteId)){
                    newUserData = {
                        ...currentUserData,
                        favourites: currentUserData.favourites.filter(fav => fav !== action.payload.quoteId)
                    }
                } else {
                    newUserData = {
                        ...currentUserData,
                        favourites: [
                            ...currentUserData.favourites,
                            action.payload.quoteId
                        ]
                    }
                }
                localStorage.setItem('userData', JSON.stringify(newUserData))
            }
        },
        setFavourites(state, action){
            action.payload.forEach(favQuote => {
                if(!state.userData.favourites.includes(favQuote.$id)){
                    state.userData.favourites.push(favQuote.$id)
                }
            })
            action.payload.filter(favQuote => state.quotes.forEach(quote => {
                if(quote.$id === favQuote.$id){
                    quote.favourite = true;
                }
            }));
        }
    }
});

export const quotesActions = quotesSlice.actions;
export default quotesSlice.reducer;