import {useRef, useState, useEffect} from "react";
import classes from './AddQuote.module.scss';
import {databases, quotesCollection} from "../api";
import {useDispatch, useSelector} from "react-redux";
import Form from "../components/Form";
import {quotesActions} from "../store/quotes";
import {useMatomo} from "@jonkoops/matomo-tracker-react";

const Login = () => {
    const { trackPageView, pushInstruction } = useMatomo();
    const userEmail = useSelector(state => state.auth.providerUid);
    useEffect(() => {
        pushInstruction('setUserId', userEmail);
        trackPageView({
            documentTitle: 'Add Quote'
        });
    }, [trackPageView, pushInstruction, userEmail]);
    const userInfo = useSelector(state => state.auth)
    const quotes = useSelector(state => state.quotes.quotes)
    const [quoteIsValid, setQuoteIsValid] = useState(false);
    const [quoteHasChanged, setQuoteHasChanged] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const quoteRef = useRef();
    const dispatch = useDispatch();
    const {trackEvent} = useMatomo();
    const onFormSubmitHandler = async e => {
        e.preventDefault();
        setError('');
        setSuccess('');
        const quoteText = quoteRef.current.value;

        if(quotes.some(quote => quote.text === quoteText)){
            setError('The quote you are trying to add already exists, please add something new!')
            setTimeout(() => setError(''), 3000);
            return;
        }

        let promise = databases.createDocument(quotesCollection, 'unique()', {
            text: quoteText,
            // ip: userInfo.ip,
            userId: userInfo.userId,
            // providerUid: userInfo.providerUid,
            timeStamp: Date.now(),
            // country: userInfo.country
        });

        promise.then(function (response) {
            dispatch(quotesActions.addQuote(response));
            trackEvent({
                category: 'Admin',
                action: 'Added New Quote',
                name: quoteText
            });
            setSuccess('Your quote has been added successfully, you may return to the homepage or add another quote.');
            setTimeout(() => setSuccess(''), 7000);
        }, function (error) {
            setError(error.toString())
            setTimeout(() => setError(''), 7000);
        });

        quoteRef.current.value = '';
    };
    const onQuoteChangeHandler = e => {
        setQuoteHasChanged(true);
        if(quoteRef.current.value.length>9){
            setQuoteIsValid(true);
        } else {
            setQuoteIsValid(false);
        }
    };
    return (
        <Form formHandler={onFormSubmitHandler}>
            <div className={`${classes['u-margin-bottom-medium-small']}`}>
                <h2 className={classes["heading-primary"]}>
                    Say something sweet
                </h2>
            </div>
            <div className={classes["form__group"]}>
                <textarea rows={5} cols={60} id="quote" minLength={8} className={classes["form__input"]} placeholder="Diabetes awaits..." required ref={quoteRef} onChange={onQuoteChangeHandler}/>
                <label htmlFor="quote" className={classes["form__label"]} style={{marginLeft: '4.5rem'}}>Diabetes awaits...</label>
                {error && <p htmlFor="password" className={classes["form__error"]}>{error}</p>}
                {success && <p htmlFor="password" className={classes["form__success"]}>{success}</p>}
            </div>
            <div className={classes["form__group"]}>
                <button className={`${classes['button']}`} disabled={quoteHasChanged && !quoteIsValid}>Add Quote</button>
            </div>
        </Form>
    );
};

export default Login;