import classes from './FavouriteQuotes.module.scss';
import {useSelector} from "react-redux";
import Quote from "../components/Quote";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {useEffect} from "react";

const FavouriteQuotes = ({setToggledTrue, setFav}) => {
    const { trackPageView, pushInstruction } = useMatomo();
    const userEmail = useSelector(state => state.auth.providerUid);
    useEffect(() => {
        pushInstruction('setUserId', userEmail);
        trackPageView({
            documentTitle: 'Favourite Quotes'
        });
    }, [trackPageView, pushInstruction, userEmail]);
    const quotes = useSelector(state => state.quotes)
    const favListLength = useSelector(state => state.quotes.userData.favourites.length);
    return (
        <>
            <h1 className={`${classes['heading-primary']} ${classes['u-margin-bottom-small']}`}>Favourite Sweet Quotes</h1>
            <div className={classes.cards}>
                {[...quotes.quotes].reverse().map(quote => {
                    if(quote.favourite === true){
                        return <Quote
                            key={quote.$id}
                            setToggledTrue={setToggledTrue}
                            quote={quote ? quote : 'loading...'}
                        />
                    }
                    return null;
                })}
                {favListLength===0 && <p className={classes['heading-secondary']} style={{maxWidth: '50rem', textTransform: 'inherit'}}>You have not favourited any quotes, please favorite some quotes to view them here.</p>}
            </div>
        </>
    )
}

export default FavouriteQuotes;