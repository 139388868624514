import classes from './Layout.module.scss';
import {Fragment} from "react";
import Header from "./Header";

const Layout = ({children}) => {
    return  (
        <Fragment>
            <Header/>
            <div className={classes["container"]}>{children}</div>
        </Fragment>
    )
}

export default Layout;