import {useSelector} from "react-redux";
import Quote from "../components/Quote";
import classes from './AllQuotes.module.scss';
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {useEffect} from "react";

const AllQuotes = ({setToggledTrue}) => {
    const { trackPageView, pushInstruction } = useMatomo();
    const userEmail = useSelector(state => state.auth.providerUid);
    useEffect(() => {
        pushInstruction('setUserId', userEmail);
        trackPageView({
            documentTitle: 'All Quotes'
        });
    }, [trackPageView, pushInstruction, userEmail]);
    const quotes = useSelector(state => state.quotes)
    return (
        <>
            <h1 className={`${classes['heading-primary']} ${classes['u-margin-bottom-small']}`}>All Sweet Quotes</h1>
            <div className={classes.cards}>
                {[...quotes.quotes].reverse().map(quote => (
                    <Quote
                        key={quote.$id}
                        setToggledTrue={setToggledTrue}
                        quote={quote ? quote : 'loading...'}
                        // quoteId={quote ? quote.$id : null}
                    />
                ))}
            </div>
        </>
    )

}

export default AllQuotes;