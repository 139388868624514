import classes from './Home.module.scss';
import Quote from "../components/Quote";
import {Fragment, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useMatomo} from "@jonkoops/matomo-tracker-react";

const Home = ({setToggledTrue}) => {
    const { trackPageView, trackEvent, pushInstruction } = useMatomo();
    const userEmail = useSelector(state => state.auth.providerUid);
    useEffect(() => {
        pushInstruction('setUserId', userEmail);
        trackPageView({
            documentTitle: 'Random Quote'
        });
    }, [trackPageView, pushInstruction, userEmail]);
    const [quoteIndex, setQuoteIndex] = useState(-1)
    const quotesList = useSelector(state => state.quotes.quotes);
    const quotesLength = useSelector(state => state.quotes.total);
    const index = useMemo(() => Math.floor(Math.random()*quotesLength), [quotesLength]);
    useEffect(() => {
        setQuoteIndex(index);
    }, [index])
    const loading = 'Loading...';
    const generateNewQuoteHandler = e => {
        e.preventDefault();
        setQuoteIndex(Math.floor(Math.random()*quotesLength));
        trackEvent({
            category: 'Random Quote',
            action: 'Generated New Quote',
            name: quotesList[quoteIndex].text
        });
    }
    return (
        <Fragment>
            <h1 className={`${classes['heading-primary']} ${classes['u-margin-bottom-small']}`}>Random Sweet Quote</h1>
            <div className={classes["cards"]}>
                <Quote
                    setToggledTrue={setToggledTrue}
                    quote={quotesList[quoteIndex] ? quotesList[quoteIndex] : loading}
                    quoteId={quotesList[quoteIndex] ? quotesList[quoteIndex].$id : null}
                />
                <button onClick={generateNewQuoteHandler} className={`${classes['button']} ${classes['u-margin-top-medium']}`}>Generate New Quote</button>
            </div>
        </Fragment>
    )
};

export default Home;