import {useRef, useState, useEffect} from "react";
import classes from './Login.module.scss';
import {account, locale} from "../api";
import {useDispatch} from "react-redux";
import {authActions} from "../store/auth";
import {Link, useHistory} from "react-router-dom";
import Form from "../components/Form";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
const Login = ({setFav, setToggledTrue}) => {
    const { trackPageView, pushInstruction, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView({
            documentTitle: 'Login'
        });
    }, [trackPageView]);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [passwordHasChanged, setPasswordHasChanged] = useState(false);
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [emailHasChanged, setEmailHasChanged] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const passwordRef = useRef();
    const emailRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const onFormSubmitHandler = async e => {
        e.preventDefault();
        let promise = account.createEmailSession(emailRef.current.value, passwordRef.current.value);
        const local = await locale.get()
        promise.then(function (response) {
            setSuccess('Success');
            pushInstruction('setUserId', response.providerUid);
            dispatch(authActions.login({...response, ...local}));
            setFav(true);
            history.push('/')
            trackEvent({
                category: 'Auth',
                action: 'LoggedIn',
                name: response.providerUid
            });
        }, function (error) {
            setError(error.toString().substring(error.toString().indexOf(':') + 1))
        });
    }
    const onPasswordChangeHandler = e => {
        setPasswordHasChanged(true);
        if(passwordRef.current.value.length>7){
            setPasswordIsValid(true);
        } else {
            setPasswordIsValid(false);
        }
    };
    const onEmailChangeHandler = e => {
        setEmailHasChanged(true);
        if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailRef.current.value)){
            setEmailIsValid(true);
        } else {
            setEmailIsValid(false);
        }
    };
    return (
            <Form formHandler={onFormSubmitHandler}>
                <div className={`${classes['u-margin-bottom-medium']}`}>
                    <h2 className={classes["heading-primary"]}>
                        Please login to continue
                    </h2>
                </div>
                <div className={classes["form__group"]}>
                    <input type="email" id="email" className={classes["form__input"]} placeholder="Email address" required ref={emailRef} onChange={onEmailChangeHandler}/>
                    <label htmlFor="email" className={classes["form__label"]}>Email address</label>
                </div>
                <div className={classes["form__group"]}>
                    <input type="password" id="password" minLength={8} className={classes["form__input"]} placeholder="Password" required ref={passwordRef} onChange={onPasswordChangeHandler}/>
                    <label htmlFor="password" className={classes["form__label"]}>Password</label>
                    {error && <p htmlFor="password" className={classes["form__error"]}>{error}</p>}
                    {success && <p htmlFor="password" className={classes["form__success"]}>{success}</p>}
                </div>
                <div className={classes["form__group"]}>
                    <button className={`${classes['button']}`} disabled={(passwordHasChanged && !passwordIsValid) || (emailHasChanged && !emailIsValid)}>Login &rarr;</button>
                    <Link to='/forgot-password' className={classes['link']}>Forgot password?</Link>
                </div>
            </Form>
    )
};

export default Login;