import {useRef, useState, useEffect} from "react";
import classes from './Login.module.scss';
import {account, databases, locale, userDataCollection} from "../api";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../store/auth";
import {useHistory} from "react-router-dom";
import Form from "../components/Form";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
const SignUp = () => {
    const userDataObject = useSelector(state => state.quotes.userData);
    const { trackPageView, pushInstruction, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView({
            documentTitle: 'Sign Up'
        });
    }, [trackPageView]);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [passwordHasChanged, setPasswordHasChanged] = useState(false);
    const [nameIsValid, setNameIsValid] = useState(false);
    const [nameHasChanged, setNameHasChanged] = useState(false);
    const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(false);
    const [confirmPasswordHasChanged, setConfirmPasswordHasChanged] = useState(false);
    const [passwordNotSame, setPasswordNotSame] = useState('');
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [emailHasChanged, setEmailHasChanged] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    // useEffect(()=>{
    //     let userData = databases.createDocument(userDataCollection, 'unique()', {});
    //     userData.then(function (response) {
    //         setSuccess('Success');
    //     }, function (error) {
    //         setError(error.toString().substring(error.toString().indexOf(':') + 1))
    //     });
    // }, [])
    const onFormSubmitHandler = async e => {
        e.preventDefault();
        let promise = account.create('unique()', emailRef.current.value, confirmPasswordRef.current.value, nameRef.current.value);
        const local = await locale.get()
        promise.then(function (response) {
            setSuccess('Success');
            pushInstruction('setUserId', emailRef.current.value);
            trackEvent({
                category: 'Auth',
                action: 'SignedUp',
                name: emailRef.current.value
            });
            let login = account.createEmailSession(emailRef.current.value, confirmPasswordRef.current.value);
            login.then(function (response) {
                setSuccess('Success');
                pushInstruction('setUserId', response.providerUid);
                dispatch(authActions.login({...response, ...local}));
                let userData = databases.createDocument(userDataCollection, response.userId, {...userDataObject});
                userData.then(function (response) {
                    setSuccess('Success');
                }, function (error) {
                    setError(error.toString().substring(error.toString().indexOf(':') + 1))
                });
                history.push('/')
            }, function (error) {
                setError(error.toString().substring(error.toString().indexOf(':') + 1))
            });
        }, function (error) {
            setError(error.toString().substring(error.toString().indexOf(':') + 1))
        });
    }
    const onPasswordChangeHandler = e => {
        setPasswordHasChanged(true);
        if(passwordRef.current.value.length>7){
            setPasswordIsValid(true);
        } else {
            setPasswordIsValid(false);
        }
    };
    const onEmailChangeHandler = e => {
        setEmailHasChanged(true);
        if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailRef.current.value)){
            setEmailIsValid(true);
        } else {
            setEmailIsValid(false);
        }
    };
    const onConfirmPasswordChangeHandler = e => {
        setConfirmPasswordHasChanged(true);
        if(confirmPasswordRef.current.value.length>7){
            setConfirmPasswordIsValid(true);
        } else {
            setConfirmPasswordIsValid(false);
        }
        if(passwordRef.current.value === confirmPasswordRef.current.value){
            setPasswordNotSame('');
        }
    }
    const onConfirmPasswordBlurHandler = e => {
        if(passwordRef.current.value !== confirmPasswordRef.current.value){
            setPasswordNotSame('The password entered does not match the one above');
        } else {
            setPasswordNotSame('');
        }
    }
    const onNameChangeHandler = e => {
        setNameHasChanged(true);
        if(nameRef.current.value.length>2){
            setNameIsValid(true);
        } else {
            setNameIsValid(false);
        }
    };
    return (
        <Form formHandler={onFormSubmitHandler}>
            <div className={`${classes['u-margin-bottom-medium']}`}>
                <h2 className={classes["heading-primary"]}>
                    Please create an account
                </h2>
            </div>
            <div className={classes["form__group"]}>
                <input type="name" id="name" className={classes["form__input"]} placeholder="Name" required ref={nameRef} onChange={onNameChangeHandler}/>
                <label htmlFor="name" className={classes["form__label"]}>Name</label>
            </div>
            <div className={classes["form__group"]}>
                <input type="email" id="email" className={classes["form__input"]} placeholder="Email address" required ref={emailRef} onChange={onEmailChangeHandler}/>
                <label htmlFor="email" className={classes["form__label"]}>Email address</label>
            </div>
            <div className={classes["form__group"]}>
                <input type="password" id="password" minLength={8} className={classes["form__input"]} placeholder="Password" required ref={passwordRef} onChange={onPasswordChangeHandler}/>
                <label htmlFor="password" className={classes["form__label"]}>Password</label>
            </div>
            <div className={classes["form__group"]}>
                <input type="password" id="passwordAgain" minLength={8} className={classes["form__input"]} placeholder="Confirm Password" required ref={confirmPasswordRef} onChange={onConfirmPasswordChangeHandler} onBlur={onConfirmPasswordBlurHandler}/>
                <label htmlFor="passwordAgain" className={classes["form__label"]}>Confirm Password</label>
                {error && <p htmlFor="password" className={classes["form__error"]}>{error}</p>}
                {success && <p htmlFor="password" className={classes["form__success"]}>{success}</p>}
                {passwordNotSame && <p htmlFor="passwordAgain" className={classes["form__error"]}>{passwordNotSame}</p>}
            </div>
            <div className={classes["form__group"]}>
                <button className={`${classes['button']}`} disabled={(passwordHasChanged && confirmPasswordHasChanged && !passwordIsValid && !confirmPasswordIsValid) || (emailHasChanged && !emailIsValid) || (nameHasChanged && !nameIsValid) || passwordNotSame}>Create Account &rarr;</button>
            </div>
        </Form>
    )
};

export default SignUp;