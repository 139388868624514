import classes from './Header.module.scss';
import {BiMenuAltRight} from "react-icons/bi";
import {AiOutlineClose} from "react-icons/ai";
import {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {account} from "../api";
import {authActions} from "../store/auth";


const Header = () => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const curator = useSelector(state => state.auth.curator);
    const history = useHistory();
    const [menuOpen, setMenuOpen] = useState(false);
    const [size, setSize] = useState({
        width: undefined,
        height: undefined
    })
    const dispatch = useDispatch();
    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        };
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, []);
    useEffect(() => {
        if (size.width > 768 && menuOpen) {
            setMenuOpen(false);
        }
    }, [size.width, menuOpen])
    const menuToggleHandler = e => {
        e.preventDefault();
        setMenuOpen(state => !state)
    }
    const mobileMenuBackgroundCloseHandler = e => {
        e.preventDefault();
        if(menuOpen){
            menuToggleHandler(e)
            console.log('closed')
        }
    };
    const loginButtonHandler = e => {
        e.preventDefault();
        history.push('/login')
    }
    const logoutButtonHandler = e => {
        e.preventDefault();
        let promise = account.deleteSession('current');
        promise.then(null, function (error) {
            // console.log(error); // Failure
        });
        dispatch(authActions.logout(null));
    }
    return (
        <header className={classes["header"]}>
            <div className={classes["header__content"]}>
                <Link to="/" className={classes['header__content__logo']}>SweetQUOTES</Link>
                <nav className={`${classes["header__content__nav"]} ${menuOpen && classes['isMenu']}`} onClick={mobileMenuBackgroundCloseHandler}>
                    <ul>
                        <li>
                            <Link to="/">Random Quote</Link>
                        </li>
                        <li>
                            <Link to="/all-quotes">All Quotes</Link>
                        </li>
                        <li>
                            <Link to="/favourite-quotes">Favourite Quotes</Link>
                        </li>
                        {(isLoggedIn && curator) &&
                            <li>
                                <Link to="/add-quote">Add Quote</Link>
                            </li>
                        }
                    </ul>
                    {!isLoggedIn && <Link className={`${classes['button']} ${classes['button--link']} ${menuOpen && classes['margin-mobile-buttons']}`} to='/sign-up'>Sign Up</Link>}
                    <button className={classes['button']} onClick={isLoggedIn ? logoutButtonHandler : loginButtonHandler}>{isLoggedIn ? "Logout" : "Login"}</button>
                </nav>
                <div className={classes["header__content__toggle"]}>
                    {menuOpen ? <AiOutlineClose onClick={menuToggleHandler}/> : <BiMenuAltRight onClick={menuToggleHandler}/>}
                </div>
            </div>
        </header>
    )
};

export default Header;