import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    userId: null,
    ip: null,
    provider: null,
    providerUid: null,
    expire: null,
    country: null,
    curator: null
};
const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        login(state, action){
            state.isLoggedIn = true;
            state.userId = action.payload.userId;
            state.ip = action.payload.ip;
            state.provider = action.payload.provider;
            state.providerUid = action.payload.providerUid;
            state.expire = action.payload.expire;
            state.country = action.payload.country;
            localStorage.setItem('expiry', action.payload.expire);
            localStorage.setItem('loggedIn', '1');
            // console.log(state);
        },
        cookieLogin(state, action){
            state.isLoggedIn = true;
            state.userId = action.payload.$id;
            state.providerUid = action.payload.email;
            state.ip = action.payload.ip;
            state.country = action.payload.country;
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(state.providerUid))
            {
                state.provider = 'email';
            }
            state.expire = localStorage.getItem('expiry') ? localStorage.getItem('expiry') : null;
            localStorage.setItem('loggedIn', '1');
            // console.log(state);
        },
        cookiePreliminaryAuth(state){
            if(localStorage.getItem('cookieFallback') && !(localStorage.getItem('cookieFallback')==="[]")){
                state.isLoggedIn = true;
            }
        },
        logout(state){
            state.isLoggedIn = false;
            state.userId = null;
            state.ip = null;
            state.provider = null;
            state.providerUid = null;
            state.expire = null;
            localStorage.removeItem('expiry');
            localStorage.removeItem('loggedIn');
            localStorage.removeItem('cookieFallback');

        },
        setCuratorStatus(state, action){
            state.curator = action.payload
        }
    }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;